import { StreamActions } from "@hotwired/turbo"

StreamActions.counts = function() {
  $('#leftnav').load('/reload_counts');
  // fetch('/reload_counts')
  //   .then(response => {
  //     if (!response.ok) {
  //       throw new Error('Network response was not ok');
  //     }
  //     return response.text();
  //   })
  //   .then(data => {
  //     document.getElementById('leftnav').innerHTML = data;
  //   })
  //   .catch(error => {
  //     console.error('There was a problem with the fetch operation:', error);
  // });

}