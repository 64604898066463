import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="select2modal"
export default class extends Controller {
  connect() {
    $('#openas').select2({
      dropdownParent: $('#largemodal'),
      containerCssClass : "form-select",
      theme: "bootstrap-5",
    });
  }
}
