import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="froala"
export default class extends Controller {
  connect() {
    var editor = new FroalaEditor('textarea#content', {
      iconsTemplate: 'font_awesome_5',
      key: 'UBB7jF4B3E3C3B2C10aIVLEABVAYFKc1Ce1MYGD1c1NYVMiB3B9B6C5C2C4D4H3H3C2==',
      imageUploadURL: '/ticketupload',
      fileUploadURL: '/ticketupload',
      imageMaxSize: 1024 * 1024 * 3,
      pastePlain: false,
      attribution: false,
      wordPasteModal: false,
      quickInsertEnabled: false,
      emoticonsUseImage: false,
      htmlUntouched: true,
      toolbarSticky: false,
      imageAddNewLine: true,
      fontSizeDefaultSelection: '14',
      enter: FroalaEditor.ENTER_DIV,
      charCounterCount: false,
      entities: '&#39;',
      requestHeaders: {
        'X-CSRF-Token': '<%= form_authenticity_token.to_s %>'
      },
      imageUploadParams: {
        id: 'my_editor'
      },
      placeholderText: 'Type something here...',
      toolbarButtons: ['bold', 'italic', 'underline', 'strikeThrough', 'paragraphFormat', 'align', 'formatOL', 'formatUL', 'outdent', 'indent', 'insertLink', 'insertFile', 'insertImage', 'html', 'emoticons', 'clearFormatting'],
      toolbarButtonsMD: ['bold', 'italic', 'underline', 'strikeThrough', 'paragraphFormat', 'align', 'formatOL', 'formatUL', 'outdent', 'indent', 'insertLink', 'insertFile', 'insertImage', 'html', 'emoticons', 'clearFormatting'],
      toolbarButtonsSM: ['bold', 'italic', 'underline', 'strikeThrough', 'paragraphFormat', 'align', 'insertLink', 'insertFile', 'insertImage', 'emoticons', 'clearFormatting'],
      toolbarButtonsXS: ['bold', 'italic', 'underline', 'insertLink', 'insertImage', 'emoticons', 'clearFormatting'],
      events: {
        'image.inserted': function ($img, response) {
          this.popups.hide('image.insert')
          this.html.insert(`<div><br></div>`)
        }
      }
    });
  }
  
  clear(event) {
    if (event.detail.success) {
      this.element.reset();
    }
    if (document.getElementById('private')) {
      document.getElementById('private').disabled = false;
    }
    if (document.getElementById('public')) {
      document.getElementById('public').disabled = false;
    }
    if (document.getElementById('resolution')) {
      document.getElementById('resolution').disabled = false;
    }
  }
  
}
