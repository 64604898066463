import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  change() {
    //console.log("hello")
    let devicetype = event.target.selectedOptions[0].value
    get(`/device_model/dropdown?type=${devicetype}`, {
      responseKind: "turbo-stream"
    })
  }
}
