require("@rails/activestorage").start()
//import "./channels"
import "./controllers"
import "./turbo_streams"
import "./jquery"
import "@hotwired/turbo-rails"
require('select2')();

const flatpickr = require("flatpickr").default

import FroalaEditor from 'froala-editor/js/froala_editor.min.js'
global.FroalaEditor = FroalaEditor
import "froala-editor/js/plugins.pkgd.min.js"
import "timeago"
import "chartkick/chart.js"
import * as bootstrap from "bootstrap"
window.bootstrap = bootstrap

document.addEventListener("turbo:before-cache", function () {
  if ($('body').hasClass('modal-open')) {
    $('#reusable_modal').modal('hide');
    $('.modal-backdrop').remove();
  }
})

document.addEventListener("turbo:load", function () { $('#opncnt').removeClass('hidden'); })
document.addEventListener("turbo:load", function () { $('#pndcnt').removeClass('hidden'); })

document.addEventListener("turbo:before-cache", function () {
  if ($('body').hasClass('modal-open')) {
    $('#reusable_modal_big').modal('hide');
    $('.modal-backdrop').remove();
  }
})

$(document).on('click', 'div.post-body img', function () {
  window.open(this.src, '_blank');
});

$(document).on('click', 'div.card-text img', function () {
  window.open(this.src, '_blank');
});

$(document).on('click', 'div.priv-body img', function () {
  window.open(this.src, '_blank');
});

$(document).on('click', 'div.res-body img', function () {
  window.open(this.src, '_blank');
});

$(document).on('click', 'div.ticket-content img', function () {
  window.open(this.src, '_blank');
});

document.addEventListener("turbo:before-cache", function () {
  if ($('#openas').hasClass("select2-hidden-accessible")) {
    $('#openas').select2('destroy');
  }
  if (typeof editor !== 'undefined' && editor !== null) {
    editor.destroy();
    editor = null;
    $('.show-placeholder').empty();
  }
});

$( "#close" ).change(function() {
  if(this.checked) {
    $('#private').prop('disabled', true);
    $('#private').addClass("disabled");
  }
  else {
    $('#private').prop('disabled', false);
    $('#private').removeClass("disabled");
  }
});

$(document).on('ajaxError', function (event, xhr) {
  if (xhr.status === 401 || xhr.status === 403) {
    window.location.href = "/users/sign_in";
  }
});

$(document).on('turbo:load', function () {
  if (sessionStorage.getItem("menu") == 'hide') {
    document.querySelector('.dash-left-nav').classList.add('hide');
  }
});

$(document).on('turbo:load', function () {
  $('div.res-body img').addClass('img-fluid');
});

$(document).on('turbo:load', function () {
  $('div.post-body img').addClass('img-fluid');
});

$(document).on('ajaxComplete', function () {
  $('div.res-body img').addClass('img-fluid');
  $('table').wrap('<div class="table-responsive"></div>');
});

$(document).on('turbo:load', function () {
  $('div.card-text img').addClass('img-fluid');
  $('table').wrap('<div class="table-responsive"></div>');
});

$(document).on('click', '#bars-menu', function () {
  document.querySelector('.dash-left-nav').classList.add('slideit');
  document.querySelector('.dash-left-nav').classList.toggle('hide');
  if (sessionStorage.getItem("menu") == 'hide') {
    sessionStorage.setItem("menu", "show");
  } else {
    sessionStorage.setItem("menu", "hide");
  }
});

$(document).on('click', '#assignInput', function () {
  $("#assignInput").on("keyup input", function () {
    var value = $(this).val().toLowerCase();
    $(".assigndropdown a").filter(function () {
      $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
    });
  });
});

$(document).on('click', '#reownInput', function () {
  $("#reownInput").on("keyup input", function () {
    var value = $(this).val().toLowerCase();
    $(".reowndropdown a").filter(function () {
      $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
    });
  });
});

$(document).on('click', '#labelInput', function () {
  $("#labelInput").on("keyup input", function () {
    var value = $(this).val().toLowerCase();
    $(".labeldropdown a").filter(function () {
      $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
    });
  });
});

window.addEventListener('turbo:load', function() {
  document.querySelector('html').style.scrollBehavior = 'smooth'
});
window.addEventListener('turbo:before-visit', function() {
  document.querySelector('html').style.scrollBehavior = 'unset'
});

window.onscroll = function () { scrollFunction() };

function scrollFunction() {
  if (document.body.scrollTop > 200 || document.documentElement.scrollTop > 200) {
    document.getElementById("myBtn").style.display = "block";
  } else {
    document.getElementById("myBtn").style.display = "none";
  }
};

$(document).on('click', '.dualfactor', function () {
  $("#twofa").toggleClass('d-none');
  $(".facbut").toggleClass('active');
  $(".facbut").trigger("blur");
});

$(document).on('click', '.chapter-toggle', function () {
    $(this).next("ul").slideToggle();
    $(this).find('i').prev('i').toggleClass('fa-caret-right fa-caret-down')
});

// When the user clicks on the button, scroll to the top of the document
//function topFunction() {
$(document).on('click', '#myBtn', function () {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
});

// function darkTheme() {
//   $('head').append('<link rel="stylesheet" type="text/css" href="/assets/dark.scss">');
// };
